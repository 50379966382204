import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheck, faTimes, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import '../styles/EndpointTable.css';

const getPathname = (url) => {
  try {
    const urlObj = new URL(url);
    return urlObj.pathname;
  } catch (error) {
    return url; // If URL construction fails, return the original string
  }
};

const getStatusIcons = (endpoint) => {
  if (endpoint.rationale === 'Match') {
    return {
      jsonIcon: <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />,
      excelIcon: <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
    };
  } else if (endpoint.rationale === 'Missing in JSON') {
    return {
      jsonIcon: <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />,
      excelIcon: <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
    };
  } else if (endpoint.rationale === 'Exists only in JSON') {
    return {
      jsonIcon: <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />,
      excelIcon: <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
    };
  }
};

const EndpointTable = ({ endpoints, onEndpointClick, handleSortChange, sortDirection, boldEndpoint }) => {
  const hasMissingInJson = endpoints.some(endpoint => endpoint.rationale === 'Missing in JSON');
  const hasExistsOnlyInJson = endpoints.some(endpoint => endpoint.rationale === 'Exists only in JSON');
  const hasRedHighlightedUrls = endpoints.some(endpoint => endpoint.rationale === 'Missing in JSON' && boldEndpoint(getPathname(endpoint.url))?.props?.style?.color === 'red');

  const hasKeyMessages = hasRedHighlightedUrls || hasMissingInJson || hasExistsOnlyInJson;

  return (
    <div className="endpoint-table-container">
      {/* {hasKeyMessages && (
        <div className="key-messages mb-3" style={{ padding: "8px" }}>
          {hasRedHighlightedUrls && (
            <div className="key-message" style={{ color: 'red' }}>
              <FontAwesomeIcon icon={faExclamationCircle} /> If the URL is highlighted in red, it means that the JSON URL may be formatted incorrectly. Check your Postman collection for the correct endpoint to be used.
            </div>
          )}
          {hasMissingInJson && (
            <div className="key-message" style={{ color: 'orange' }}>
              <FontAwesomeIcon icon={faExclamationTriangle} /> If the endpoint is <u>Missing in JSON</u>, the Autonomous Agents may have hallucinated an endpoint. Please check your Postman collection for the source of truth.
            </div>
          )}
          {hasExistsOnlyInJson && (
            <div className="key-message" style={{ color: 'orange' }}>
              <FontAwesomeIcon icon={faExclamationTriangle} /> If the endpoint has a status of <u>Exists only in JSON</u>, the endpoint may not have been picked up by the Autonomous Agent. Please reach out for support if this is the case.
            </div>
          )}
        </div>
      )} */}
      <table className="endpoint-table">
        <thead>
          <tr>
            <th style={{ width: '150px' }}>HTTP Method:<br />Source</th>
            <th style={{ width: '150px' }}>HTTP Method:<br />Target</th>
            <th onClick={handleSortChange} style={{ cursor: "pointer", width: '200px', textAlign: 'left' }}>
              Endpoints {sortDirection === "asc" ? "▲" : "▼"}
            </th>
            <th style={{ width: '100px' }}>Postman Collection</th>
            <th style={{ width: '100px' }}>APIDNA</th>
            <th style={{ width: '120px' }}>Status</th>
            <th style={{ width: '100px' }}>Details</th>
          </tr>
        </thead>
        <tbody>
          {endpoints.map((endpoint, index) => {
            const { jsonIcon, excelIcon } = getStatusIcons(endpoint);
            const boldedEndpoint = boldEndpoint(getPathname(endpoint.url));
            const isEyeIconDisabled = endpoint.rationale === 'Missing in JSON' || endpoint.rationale === 'Exists only in JSON';

            return (
              <tr key={index} style={{ cursor: "default" }}>
                <td style={{ width: '150px' }}>{endpoint.jsonMethod || "N/A"}</td>
                <td style={{ width: '150px' }}>{endpoint.method || "N/A"}</td>
                <td style={{ width: '200px', textAlign: 'left' }}>{boldedEndpoint || <span style={{ color: 'red' }}>{getPathname(endpoint.url)}</span>}</td>
                <td style={{ width: '100px' }}>{jsonIcon}</td>
                <td style={{ width: '100px' }}>{excelIcon}</td>
                <td style={{ width: '120px' }}>{endpoint.rationale}</td>
                <td style={{ width: '100px' }}>
                  {isEyeIconDisabled ? (
                    <FontAwesomeIcon icon={faEye} style={{ color: 'grey' }} title='Unable to compare data' />
                  ) : (
                    <button
                      className="btn btn-link mt-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => onEndpointClick(endpoint)}
                    >
                      <FontAwesomeIcon icon={faEye} title='Click to view details' />
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EndpointTable;
