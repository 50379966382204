import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../styles/Filter.css';

const MethodFilter = ({ methods, handleMethodChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const httpMethods = ["GET", "PATCH", "POST", "PUT", "DELETE"];

  const handleCheckboxChange = (method) => {
    if (methods.includes(method)) {
      handleMethodChange(methods.filter(m => m !== method));
    } else {
      handleMethodChange([...methods, method]);
    }
  };

  return (
    <div className="method-filter-dropdown">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>
          Methods
        </DropdownToggle>
        <DropdownMenu>
          {httpMethods.map(method => (
            <DropdownItem key={method} toggle={false}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`method-${method}`}
                  value={method}
                  checked={methods.includes(method)}
                  onChange={() => handleCheckboxChange(method)}
                />
                <label className="form-check-label" htmlFor={`method-${method}`}>
                  {method}
                </label>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default MethodFilter;