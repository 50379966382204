import React, { useState } from 'react';
import faqData from '../utils/faqData.json';
import '../styles/FAQs.css';

const FAQs = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('All Questions');

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Collapse if the same index is clicked again
    } else {
      setExpandedIndex(index); // Expand the clicked FAQ
    }
  };

  const renderFAQs = (faqs) => (
    <div>
      {faqs.map((faq, index) => (
        <div key={index} className='card my-3'>
          <div 
            className='card-header bg-white d-flex justify-content-between align-items-center' 
            onClick={() => toggleExpand(index)}
            style={{ cursor: 'pointer' }}
          >
            <h6 className='mb-0'>{faq.question}</h6>
            <span>{expandedIndex === index ? '-' : '+'}</span>
          </div>
          {expandedIndex === index && (
            <div className='card-body px-4 py-2 bg-white text-black'>
              <p>{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const renderTabContent = () => {
    if (activeTab === 'All Questions') {
      return Object.entries(faqData).map(([category, faqs]) => (
        <div key={category} className='mb-5'>
          <h5 className='mt-5'>{category}</h5>
          {renderFAQs(faqs)}
        </div>
      ));
    } else {
      return renderFAQs(faqData[activeTab]);
    }
  };

  return (
    <div className='bg-white text-black mt-5'>
      <div className='container my-5'>
        <h3 className='text-center' style={{color: 'rgb(255, 117, 24)'}}>Frequently Asked Questions</h3>
        <div className='row justify-content-center my-5'>
        <div className='col-12 mb-2 d-flex flex-wrap justify-content-center'>
          <button
            className={`btn btn-link ${activeTab === 'All Questions' ? 'text-primary' : 'text-dark'}`}
            onClick={() => setActiveTab('All Questions')}
          >
            All Questions
          </button>
          {Object.keys(faqData).map((category) => (
            <button
              key={category}
              className={`btn btn-link ${activeTab === category ? 'text-primary' : 'text-dark'}`}
              onClick={() => setActiveTab(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div>
          {renderTabContent()}
        </div>
        </div>
      </div>
    </div>
  );
}

export default FAQs;
