import React, { useState } from "react";
import { ReactComponent as Logo } from "../logo.svg";
import "../styles/Navbar.css";
import { FaHeadset } from "react-icons/fa6";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
} from "mdb-react-ui-kit";

const Navbar = () => {
  const [openBasic, setOpenBasic] = useState(false);

  return (
    <div>
      <MDBNavbar expand="lg" dark bgColor="black">
        <MDBContainer fluid>
          <MDBNavbarBrand
            href="https://www.apidna.ai"
            target="_blank"
            rel="noreferrer"
            aria-label="Visit API DNA's homepage"
          >
            <Logo
              alt="APIDNA Logo"
              className="navbar-logo my-0"
              width={223}
              height={66}
              style={{ marginRight: "10px" }}
            />
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpenBasic(!openBasic)}
            className="mt-0"
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar open={openBasic} className="justify-content-end">
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink
                  active
                  aria-current="page"
                  className="validator-home"
                >
                  VALIDATOR TOOL
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  href="https://agent.apidna.io/sign-in?redirectUrl=/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LOGIN
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  href="https://agent.apidna.io/sign-up"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Sign up to API DNA"
                >
                  SIGN UP
                </MDBNavbarLink>
              </MDBNavbarItem>
              <a
                href="https://apidna.ai/contact-us/"
                target="_blank"
                rel="noreferrer"
                className="support-btn d-flex justify-content-center align-items-center"
                aria-label="Contact API DNA"
              >
                <FaHeadset style={{ marginRight: "5px" }} />
                Need Support?
              </a>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

export default Navbar;
