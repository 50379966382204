import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { calculateOverallMatchRate } from "./overallMatchRate";
import "../styles/HeadBodyModal.css";

export const HeadBodyModal = ({
  show,
  onHide,
  endpoint,
  comparisonResult,
  comparisonResultHeader,
  jsonFile,
  excelFile,
  onUpdateOverallMatchRate // New prop to handle updating the overall match rate
}) => {
  const [percentageDetail, setPercentageDetail] = useState({ percentage: 0, totalKeysMatchedCount: 0, totalKeysComparedCount: 0 });
  const [matchRates, setMatchRates] = useState({ header: "N/A", body: "N/A", query: "N/A", path: "N/A" });
  const [overallMatchRate, setOverallMatchRate] = useState("N/A");

  useEffect(() => {
    const calculateMatchPercentage = () => {
      if (!comparisonResult || !comparisonResultHeader) {
        return {
          percentage: 0,
          totalKeysMatchedCount: 0,
          totalKeysComparedCount: 0,
        };
      }

      let totalKeysComparedCount =
        (comparisonResult.excelQueryKeys ? comparisonResult.excelQueryKeys.length : 0) +
        (comparisonResult.excelPathKeys ? comparisonResult.excelPathKeys.length : 0) +
        (comparisonResult.excelKeys ? comparisonResult.excelKeys.length : 0) +
        (comparisonResultHeader.excelKeys ? comparisonResultHeader.excelKeys.length : 0);
      let totalKeysMatchedCount = comparisonResult.matchCount + (comparisonResultHeader.matchedKeys ? comparisonResultHeader.matchedKeys.length : 0);

      if (totalKeysComparedCount === 0) {
        return {
          percentage: 0,
          totalKeysMatchedCount: 0,
          totalKeysComparedCount: 0,
        };
      }

      const percentage = (totalKeysMatchedCount / totalKeysComparedCount) * 100;

      return {
        percentage,
        totalKeysMatchedCount,
        totalKeysComparedCount,
      };
    };

    const calculateMatchRate = (jsonKeys, excelKeys) => {
      if (!jsonKeys || !excelKeys) {
        return "N/A";
      }

      const jsonKeySet = new Set(jsonKeys.map(keyObj => keyObj.key));
      const matchedKeysCount = excelKeys.filter(keyObj => jsonKeySet.has(keyObj.key)).length;

      if (jsonKeys.length === 0) return "N/A";

      return ((matchedKeysCount / jsonKeys.length) * 100).toFixed(2);
    };

    const overallMatchRate = calculateOverallMatchRate(comparisonResult, comparisonResultHeader);

    const headerMatchRate = calculateMatchRate(comparisonResultHeader?.jsonKeys, comparisonResultHeader?.excelKeys);
    const bodyMatchRate = calculateMatchRate(comparisonResult?.jsonKeys, comparisonResult?.excelKeys);
    const queryMatchRate = calculateMatchRate(comparisonResult?.jsonQueryKeys, comparisonResult?.excelQueryKeys);
    const pathMatchRate = calculateMatchRate(comparisonResult?.jsonPathKeys, comparisonResult?.excelPathKeys);

    setPercentageDetail(calculateMatchPercentage());
    setMatchRates({
      header: headerMatchRate,
      body: bodyMatchRate,
      query: queryMatchRate,
      path: pathMatchRate,
    });
    setOverallMatchRate(overallMatchRate);

    if (onUpdateOverallMatchRate) {
      onUpdateOverallMatchRate(endpoint, overallMatchRate);
    }
  }, [comparisonResult, comparisonResultHeader, endpoint, onUpdateOverallMatchRate]);

  const renderKeys = (keys) => {
    return keys.map((keyObj, index) => {
      const { key, highlight } = keyObj;
      return (
        <div
          key={index}
          style={{
            backgroundColor: highlight ? "rgba(255, 117, 24, 0.5)" : "transparent",
            padding: "0px",
            marginBottom: "0px",
          }}
        >
          <pre>
          <span className="key-word" style={{ color: highlight ? 'white' : 'black' }}>"key":</span>
            <span className="key-value">"{key}"</span>
          </pre>
        </div>
      );
    });
  };

  const renderHeader = (header) => (
    <div
      style={{
        backgroundColor: header.highlight ? "rgba(255, 117, 24, 0.5)" : "transparent",
        padding: "0px",
        marginBottom: "0px",
      }}
    >
      <pre>
      <span className="key-word" style={{ color: header.highlight ? 'white' : 'black' }}>"key":</span>
        <span className="key-value">"{header.key}"</span>
      </pre>
    </div>
  );

  // Handle error case
  if (comparisonResultHeader.error) {
    return (
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{comparisonResultHeader.error}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // Render modal content
  return (
    <Modal show={show} onHide={onHide} size="xl" centered className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title><span style={{ color: 'black'}}>Endpoint: </span>{endpoint ? endpoint.boldedEndpoint : "N/A"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="comparison-title">
          <div className="title-middle my-3">
            <span className="overall-match-rate"><span style={{ color: 'black'}}>Overall Match Rate: </span>{overallMatchRate === "N/A" ? "N/A" : `${overallMatchRate}%`}</span>
          </div>
        </div>
        <div className="match-rates" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '2rem' }}>
          <span style={{ color: 'black', textAlign: 'center'}}>Header Match Rate:<br/><span style={{ color: '#FF7518'}}>{matchRates.header === "N/A" ? "N/A" : `${matchRates.header}%`}</span></span>
          <span style={{ color: 'black', textAlign: 'center'}}>Body Match Rate:<br/><span style={{ color: '#FF7518'}}>{matchRates.body === "N/A" ? "N/A" : `${matchRates.body}%`}</span></span>
          <span style={{ color: 'black', textAlign: 'center'}}>Path Match Rate:<br/><span style={{ color: '#FF7518'}}>{matchRates.path === "N/A" ? "N/A" : `${matchRates.path}%`}</span></span>
          <span style={{ color: 'black', textAlign: 'center'}}>Query Match Rate:<br/><span style={{ color: '#FF7518'}}>{matchRates.query === "N/A" ? "N/A" : `${matchRates.query}%`}</span></span>
        </div>
        <div className="comparison-section">
          <div className="header-comparison">
            <div className="original-body scrollable">
              <h5 style={{ fontSize: '14px' }}>Source File: {jsonFile.name}</h5>
              <p className="body-text">{'Header:'}</p>
              <p>{"{"}</p>
              {comparisonResultHeader && comparisonResultHeader.jsonKeys ? (
                <div>
                  {comparisonResultHeader.jsonKeys.map((header, index) => (
                    <div key={index}>{renderHeader(header)}</div>
                  ))}
                </div>
              ) : (
                "No data"
              )}
              <p>{"}"}</p>
              <br />
              <p className="body-text">{'Body:'}</p>
              <p>{"{"}</p>
              <pre>
                {comparisonResult && comparisonResult.jsonKeys && comparisonResult.jsonKeys.length > 0
                  ? renderKeys(comparisonResult.jsonKeys)
                  : "No data"}
              </pre>
              <p>{"}"}</p>
              <br />
              <p className="body-text">{'Path:'}</p>
              <p>{"{"}</p>
              <pre>
                {comparisonResult && comparisonResult.jsonPathKeys && comparisonResult.jsonPathKeys.length > 0
                  ? renderKeys(comparisonResult.jsonPathKeys)
                  : "No data"}
              </pre>
              <p>{"}"}</p>
              <br />
              <p className="body-text">{'Query:'}</p>
              <p>{"{"}</p>
              <pre>
                {comparisonResult && comparisonResult.jsonQueryKeys && comparisonResult.jsonQueryKeys.length > 0
                  ? renderKeys(comparisonResult.jsonQueryKeys)
                  : "No data"}
              </pre>
              <p>{"}"}</p>
            </div>
            <div className="modified-body scrollable">
              <h5 style={{ fontSize: '14px' }}>Target File: {excelFile.name}</h5>
              <p className="body-text">{'Header:'}</p>
              <p>{"{"}</p>
              {comparisonResultHeader && comparisonResultHeader.excelKeys ? (
                <div>
                  {comparisonResultHeader.excelKeys.map((header, index) => (
                    <div key={index}>{renderHeader(header)}</div>
                  ))}
                </div>
              ) : (
                "No data"
              )}
              <p>{"}"}</p>
              <br />
              <p className="body-text">{'Body:'}</p>
              <p>{"{"}</p>
              <pre>
                {comparisonResult && comparisonResult.excelKeys && comparisonResult.excelKeys.length > 0
                  ? renderKeys(comparisonResult.excelKeys)
                  : "No data"}
              </pre>
              <p>{"}"}</p>
              <br />
              <p className="body-text">{'Path:'}</p>
              <p>{"{"}</p>
              <pre>
                {comparisonResult && comparisonResult.excelPathKeys && comparisonResult.excelPathKeys.length > 0
                  ? renderKeys(comparisonResult.excelPathKeys)
                  : "No data"}
              </pre>
              <p>{"}"}</p>
              <br />
              <p className="body-text">{'Query:'}</p>
              <p>{"{"}</p>
              <pre>
                {comparisonResult && comparisonResult.excelQueryKeys && comparisonResult.excelQueryKeys.length > 0
                  ? renderKeys(comparisonResult.excelQueryKeys)
                  : "No data"}
              </pre>
              <p>{"}"}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HeadBodyModal;
