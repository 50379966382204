import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../styles/Filter.css';

const PageSizeDropdown = ({ pageSize, onPageSizeChange, pageSizeOptions }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="page-size-dropdown">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>
          {pageSize}
        </DropdownToggle>
        <DropdownMenu>
          {pageSizeOptions.map(size => (
            <DropdownItem key={size} onClick={() => onPageSizeChange(size)}>
              {size}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default PageSizeDropdown;