import { extractRawUrlAndMethods } from "./extractRawUrlAndMethods";
import { getInputBaseUrl } from "../App";

export const extractJsonHeader = (jsonData, targetUrl, targetMethod) => {
  let result = null;
  const typeAuthorization = jsonData?.auth?.type;

  const traverse = (item) => {
    if (item.request && item.request.url && item.request.url.raw) {
      let url = item.request.url.raw;
      const baseUrl = getInputBaseUrl();
      const removeQueryParams = (url) => {
        return url.split('?')[0];
      };

      const jsonUrlQuery = url.replace(/\{\{.*?\}\}/g, baseUrl);
      const jsonUrl = removeQueryParams(jsonUrlQuery);
      const jsonMethod = item.request.method.toLowerCase();
      const excelMethod = targetMethod.toLowerCase();
      
      const excelUrl = removeQueryParams(targetUrl);

      if (jsonUrl === excelUrl && jsonMethod === excelMethod) {
        // Extract the headers if they exist
        const headers = item.request.header ? item.request.header.map((header) => ({
          key: header.key,
          value: header.value,
        })) : [];

       // Check the type of authorization and add "Authorization" header if necessary
        if (typeAuthorization === "bearer"){
           // Check if "Authorization" header is already present
        if (!headers.find(header => header.key === "Authorization")) {
          // Add the hardcoded "Authorization" header to the beginning
          headers.unshift({
            key: "Authorization" 
          });
        }
      } else if (typeAuthorization === "apikey") {
        // Find the object within apikey array where key is "key"
        const keyAuth = jsonData.auth.apikey.find(item => item.key === "key");
    
        if (keyAuth) {
            const Auth = keyAuth.value; // Get the value associated with "key"
    
            
            headers.unshift({
                key: Auth
            });
        }
      } 

        result = {
          headers: headers,
        };
      }
    }

    // Traverse sub-items recursively
    if (item.item) {
      item.item.forEach((subItem) => traverse(subItem));
    }
  };

  // Start traversing the JSON data
  if (jsonData.item && Array.isArray(jsonData.item)) {
    jsonData.item.forEach((item) => traverse(item));
  } else {
    console.log("No items found in the provided JSON data.");
  }

  if (result === null) {
    console.log("No matching URL found or no headers available.");
  }

  return result;
};
