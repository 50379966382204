import React from 'react';
import '../styles/StatCard.css'; // Import the CSS file

const StatCard = ({ name, figure }) => {
  return (
    <div className="card text-center h-100 d-flex align-items-center justify-content-center">
        <div className="card-body d-flex justify-content-between align-items-center flex-column">
            <h5 className="card-title name">{name}</h5>
            <p className="card-text figure">{figure}</p>
      </div>
    </div>
  );
};

export default StatCard;
