export const readJsonFile = (file) => {
    return new Promise((resolve, reject) => {
        if (!(file instanceof Blob)) {
            reject(new Error('Invalid file object.'));
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result);
                resolve(jsonData);
            } catch (err) {
                reject(new Error('Error reading JSON file.'));
            }
        };
        reader.onerror = (err) => reject(err);
        reader.readAsText(file);
    });
};
