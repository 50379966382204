import React, { useEffect, useRef } from 'react';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/hero.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SimpleSlider = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    console.log(sliderRef.current);
  }, []);

  const CustomPrevArrow = ({ onClick }) => (
    <MdOutlineNavigateBefore
      onClick={onClick}
      className="slick-arrow slick-prev"
      style={{ position: 'absolute', top: '50%', left: '0px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 1000, color: 'black' }}
    />
  );

  const CustomNextArrow = ({ onClick }) => (
    <MdOutlineNavigateNext
      onClick={onClick}
      className="slick-arrow slick-next"
      style={{ position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 1000, color: 'black' }}
    />
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  const styles = {
    slickWrapper: {
      width: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden'
    },
    slickInner: {
      width: '100%',
    },
    imageWrapper: {
      width: '100%',
      maxWidth: '650px',
    },
    slickItemWrapper: {
      maxWidth: '500px'
    },
    slickItemContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '25px'
    }
  };

  return (
    <div style={styles.slickWrapper}>
      <Slider style={styles.slickInner} {...settings} ref={sliderRef}>
        <div style={styles.slickItemWrapper}>
          <div style={styles.slickItemContainer}>
            {/* <img style={styles.imageWrapper} src="\images\stats-summary.png" alt="" /> */}
            <img style={styles.imageWrapper} src='https://apidna.eu/wp-content/uploads/2024/08/stats-summary.png' alt="summary" />
          </div>
        </div>
        <div style={styles.slickItemWrapper}>
          <div style={styles.slickItemContainer}>
            {/* <img style={styles.imageWrapper} src="/images/endpoint-table.png" alt="" /> */}
            <img style={styles.imageWrapper} src='https://apidna.eu/wp-content/uploads/2024/08//endpoint-table.png' alt="endpoint-table" />
          </div>
        </div>
        <div style={styles.slickItemWrapper}>
          <div style={styles.slickItemContainer}>
            {/* <img style={styles.imageWrapper} src="/images/modal.png" alt="" />
             */}
            <img style={styles.imageWrapper} src='https://apidna.eu/wp-content/uploads/2024/08/modal.png' alt="modal" />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SimpleSlider;
