import { getInputBaseUrl } from "../App";

export const extractJsonPathQuery = (jsonData, targetUrl, targetMethod) => {
  let result = null;

  const traverse = (item) => {
    if (item.request && item.request.url && item.request.url.raw) {
      let url = item.request.url.raw;
      const baseUrl = getInputBaseUrl();
      const removeQueryParams = (url) => url.split('?')[0];
      const jsonUrlQuery = url.replace(/\{\{.*?\}\}/g, baseUrl);
      const jsonUrl = removeQueryParams(jsonUrlQuery);
      const excelUrl = removeQueryParams(targetUrl);
      const jsonMethod = item.request.method.toLowerCase();
      const excelMethod = targetMethod.toLowerCase();

      if (jsonUrl === excelUrl && jsonMethod === excelMethod) {
        // Found the matching URL, extract the path and query content
        const path = item.request.url.path || [];
        const query = item.request.url.query || [];

        // Extract query keys and values into an object
        const jsonQueryKeysExtracted = {};
        for (let i = 0; i < query.length; i++) {
          const key = query[i].key;
          jsonQueryKeysExtracted[key] = query[i].value;
        }

        result = {
          path,
          query: jsonQueryKeysExtracted,
        };
      }
    }

    // Recursively traverse nested items
    if (item.item) {
      item.item.forEach((subItem) => traverse(subItem));
    }
  };

  // Start traversal from the top level of jsonData
  if (jsonData.item && Array.isArray(jsonData.item)) {
    jsonData.item.forEach((item) => traverse(item));
  }

  return result;
};
