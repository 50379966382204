import { readJsonFile, readExcelFile, extractJsonHeader, parseHeaderContent, extractRawUrlAndMethods } from '../utils';
import {getInputBaseUrl} from '../App'

export const performHeaderComparison = async (endpoint, jsonFile, excelFile,method) => {
    try {
      const jsonData = await readJsonFile(jsonFile);
      const excelData = await readExcelFile(excelFile);
     
      const targetUrl = endpoint.url;
      const targetMethod = method;

      const jsonHeaderData = extractJsonHeader(jsonData, targetUrl, targetMethod);

      // Extract Excel header keys
      let excelHeaderContentKeys = [];

      excelData.forEach((row) => {
        const apiDocumentation = row[5]; 

        if (apiDocumentation) {
          const documentationLinesHead = apiDocumentation.split("\n");

          documentationLinesHead.forEach((line, index) => {
            if (line.startsWith("Headers:")) {
              const headerContentKeys = parseHeaderContent(
                documentationLinesHead,
                index + 1,
                targetUrl,
                targetMethod
              );

              excelHeaderContentKeys.push(headerContentKeys);
            }
          });
        }
      });

      // Process Excel header content keys
      excelHeaderContentKeys = excelHeaderContentKeys.reduce((acc, obj) => {
        Object.keys(obj).forEach((key) => {
          try {
            const correctedJsonString = obj[key].replace(/'/g, '"');
            const parsedValue = JSON.parse(correctedJsonString);
            acc.push({
              key: key,
              value: parsedValue.type,
            });
          } catch (error) {
            console.error(`Error parsing key ${key}:`, error);
            acc.push({
              key: key,
              value: obj[key],
            });
          }
        });
        return acc;
      }, []);

      // Combine headers
      const combinedHeaders = {
        jsonHeaders:
          jsonHeaderData?.headers.map((item) => ({
            key: item.key,
            value: item.value,
          })) || [],
        excelHeaders: excelHeaderContentKeys || [],
      };

      // Prepare comparison result header
      const jsonKeys = combinedHeaders.jsonHeaders.map((header) => header.key);
      const excelKeys = combinedHeaders.excelHeaders.map(
        (header) => header.key
      );

      const matchedKeys = jsonKeys.filter((key) => excelKeys.includes(key));
      const unmatchedJsonKeys = jsonKeys.filter(
        (key) => !excelKeys.includes(key)
      );
      const unmatchedExcelKeys = excelKeys.filter(
        (key) => !jsonKeys.includes(key)
      );

      const highlightedJsonKeys = combinedHeaders.jsonHeaders.map((header) => ({
        key: header.key,
        highlight: !matchedKeys.includes(header.key),
      }));

      const highlightedExcelKeys = combinedHeaders.excelHeaders.map(
        (header) => ({
          key: header.key,
          highlight: !matchedKeys.includes(header.key),
        })
      );

      const jsonValues = combinedHeaders.jsonHeaders.map(
        (header) => header.value
      );
      const excelValues = combinedHeaders.excelHeaders.map(
        (header) => header.value
      );

      const comparisonResultHeader = {
        jsonKeys: highlightedJsonKeys,
        excelKeys: highlightedExcelKeys,
        jsonValues: jsonValues,
        excelValues: excelValues,
        matchedKeys: matchedKeys.map((key) => ({ key, highlight: true })),
        unmatchedJsonKeys: unmatchedJsonKeys.map((key) => ({
          key,
          highlight: false,
        })),
        unmatchedExcelKeys: unmatchedExcelKeys.map((key) => ({
          key,
          highlight: false,
        })),
      };

      return JSON.stringify(comparisonResultHeader);
    } catch (error) {
      console.error("Comparison Error:", error);
      return { error: "An error occurred during comparison." };
    }
  };
  export default performHeaderComparison;