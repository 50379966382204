import React, { useState } from 'react';
import { ReactComponent as Logo } from '../logo.svg';


const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // only for local, false for the production
  const isTest = false;

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) =>{
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    console.log("User's email, email: ", email);

    setError('');
    setIsSubmitting(true);

    // Wordpress contactform value
    const formData = new FormData();
    formData.append('your-email', email);
    formData.append('_wpcf7', '3435');
    formData.append('_wpcf7_version', '5.9.6');
    formData.append('_wpcf7_locale', 'en_US');
    formData.append('_wpcf7_unit_tag', 'wpcf7-f3435-o2');
    formData.append('_wpcf7_container_post', '0');

    // only for local for production testing
    const apiUrl = isTest ? 'https://apidna.ai/contact-us/#wpcf7-f3435-o2' : '/contact-us/#wpcf7-f3435-o2';

  if (isTest) {
    console.log('Test Mode: Form data to be sent', Object.fromEntries(formData.entries()));
    setIsSubmitting(false);
    setIsSubmitted(true);

    // Simulate network delay of 3 seconds only for debugging
    // setTimeout(() => {
    //   setIsSubmitting(false);
    //   setIsSubmitted(true);
    // }, 3000);

  } else {

    try{
    const res = await fetch('/contact-us/#wpcf7-f3435-o2', {
      method: 'POST',
      body: formData,
    });

    const data = await res.text();
    console.log("Success", data);
    setIsSubmitting(false);
    setIsSubmitted(true);
  } catch (error){
    console.error('Error', error);
    setIsSubmitting(false);
  }
  }
};

return(

  <div id="mc_embed_signup">
    {isSubmitted ? (
      <div>
        <a
          href="https://www.apidna.ai"
          target="_blank"
          rel="noreferrer"
          aria-label="Visit API DNA's homepage">
          <Logo
            alt="APIDNA Logo"
            className=""
            width={160}
            height={66}
          />
        </a>
        <h5>Thank you for subscribing!</h5>
      </div>
    ): (
    <form onSubmit={handleSubmit}>
      <div id="mc_embed_signup_scroll">
        <a
          href="https://www.apidna.ai"
          target="_blank"
          rel="noreferrer"
          aria-label="Visit API DNA's homepage">
          <Logo
            alt="APIDNA Logo"
            className=""
            width={160}
            height={66}
          />
          </a>
          <h5>Subscribe for updates</h5>
          <div className="mc-field-group mb-4">
            <label htmlFor="mce-EMAIL">Email Address
            </label>
            <input
              id="mc-embedded-subscribe-form"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="required email form-control footer-input"
              name="mc-embedded-subscribe-form"
              required
              placeholder="Enter your email"
              value={email}
              data-status="init"
              aria-label="Contact form"
              />
          </div>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <div id="mce-responses" className="clear foot">
            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
          </div>
          <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
            <input type="text" name="b_38087bdfe3d26f0054547d701_a28924b57c" tabIndex="-1" />
          </div>
          <div className="optionalParent">
            <div className="clear foot">
              <button
                type="submit"
                style={{
                  backgroundColor: isSubmitting ? '#808080' : '#FF7518',
                  borderColor: '#FF7518',
                  color: "whitesmoke"
                }}
                className="button btn"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Subscribe'}
              </button>
            </div>
          </div>
        </div>
      </form>
      )}
    </div>

  )


};

export default SignupForm;
