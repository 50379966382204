import { getInputBaseUrl } from "../App";

export const extractJsonBody = (jsonData, targetUrl, targetMethod) => {
  let result = null;

  const traverse = (item) => {
    if (item.request && item.request.url && item.request.url.raw) {
      let url = item.request.url.raw;
      const baseUrl = getInputBaseUrl();
      const removeQueryParams = (url) => {
        return url.split('?')[0];
      }
      const jsonUrlQuery = url.replace(/\{\{.*?\}\}/g, baseUrl);
      const jsonUrl = removeQueryParams(jsonUrlQuery);
      const excelUrl = removeQueryParams(targetUrl);
const jsonMethod = item.request.method.toLowerCase();
const excelMethod = targetMethod.toLowerCase();

      if (jsonUrl === excelUrl && jsonMethod === excelMethod) {
        // Found the matching URL, extract the body content
        if (item.request.body && item.request.body.urlencoded) {
          result = {
            method: item.request.method,
            body: item.request.body.urlencoded.map((field) => ({
              key: field.key,
              value: field.value,
              description: field.description,
              disabled: field.disabled,
            })),
          };
        } else if (item.request.body && item.request.body.raw) {
          const rawJson = item.request.body.raw;
          const bodyObject = JSON.parse(rawJson);

          // Function to extract keys from the JSON object
          const extractKeys = (obj, parentKey = '', keys = []) => {
            for (const key in obj) {
              const fullKey = parentKey ? `${parentKey}.${key}` : key;
              keys.push(fullKey);
              if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                extractKeys(obj[key], fullKey, keys);
              } else if (Array.isArray(obj[key])) {
                obj[key].forEach((item, index) => {
                  const arrayKey = `${fullKey}[${index}]`;
                  keys.push(arrayKey);
                  if (typeof item === 'object' && item !== null) {
                    extractKeys(item, arrayKey, keys);
                  }
                });
              }
            }
            return keys;
          };

          // Extract keys from the bodyObject
          const keys = extractKeys(bodyObject);
     

          // Modify result to include extracted keys
          result = {
            method: item.request.method,
            body: keys.map((key) => ({
              key: key.split('.')[0], // Take only the first part of the key
              value: bodyObject[key], // Assuming you want the value corresponding to the key
              // Add other properties as needed
            })),
          };
        }
      }
    }

    // Recursively traverse nested items
    if (item.item) {
      item.item.forEach((subItem) => traverse(subItem));
    }
  };

  // Start traversal from the top level of jsonData
  if (jsonData.item && Array.isArray(jsonData.item)) {
    jsonData.item.forEach((item) => traverse(item));
  }

  return result;
};
