import React from 'react';
import '../styles/Footer.css';
import SignupForm from './SignupForm'

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-auto pb-3">
      <hr className='mb-5 mt-0'/>
      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-md-3">
           <div id="mc_embed_shell">
            <SignupForm />

            </div>
          </div>
          <div className="col-md-2">
            <h5>New York</h5>
            <address>
              APIDNA, Inc.<br />
              601 W 26th Street<br />
              3rd Floor Suite 357<br />
              New York, NY 10001
            </address>
            <h5>Luxembourg</h5>
            <address>
              9, Rue de Laboratoire,<br />
              L-1911 Luxembourg
            </address>
          </div>
          <div className="col-md-2">
            <h5>Solution</h5>
            <ul className="list-unstyled">
              <li><a href="https://apidna.ai/#how-it-works/" target="_blank" rel="noopener noreferrer">How It Works</a></li>
              <li><a href="https://apidna.ai/#features/" target="_blank" rel="noopener noreferrer">Features</a></li>
              <li><a href="https://apidna.ai/#benefits/" target="_blank" rel="noopener noreferrer">Benefits</a></li>
              <li><a href="https://apidna.ai/plan-pricing/" target="_blank" rel="noopener noreferrer">Pricing</a></li>
              <li><a href="https://agent.apidna.io/" target="_blank" rel="noopener noreferrer">Login</a></li>
              <li><a href="https://agent.apidna.io/sign-up/" target="_blank" rel="noopener noreferrer">Sign Up</a></li>
            </ul>
          </div>
          <div className="col-md-2">
            <h5>Company</h5>
            <ul className="list-unstyled">
              <li><a href="https://apidna.ai/about-us/" target="_blank" rel="noopener noreferrer">About Us</a></li>
              <li><a href="https://apidna.ai/blogs/" target="_blank" rel="noopener noreferrer">Blog</a></li>
              <li><a href="https://apidna.ai/contact-us/" target="_blank" rel="noopener noreferrer">Contact</a></li>
            </ul>
          </div>
          <div className="col-md-2">
            <h5>Legal</h5>
              <ul>
                <li><a href="https://apidna.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                <li><a href="https://apidna.ai/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms of Conditions</a></li>
              </ul>
          </div>
        </div>
        <div className="text-center mt-4">
          <p>© APIDNA {currentYear}. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
