import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../styles/Filter.css';

const FilterDropdown = ({ filter, handleFilterChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="filter-dropdown">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>
          {filter}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleFilterChange('All')}>All</DropdownItem>
          <DropdownItem onClick={() => handleFilterChange('Matching')}>Matching</DropdownItem>
          <DropdownItem onClick={() => handleFilterChange('Not Matching')}>Not Matching</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default FilterDropdown;