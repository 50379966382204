// src/utils/utils.js

export const calculateOverallMatchRate = (comparisonResult, comparisonResultHeader) => {
    if (!comparisonResult || !comparisonResultHeader) {
      return "N/A";
    }
  
    const totalJsonKeysCount = (
      (comparisonResult.jsonQueryKeys ? comparisonResult.jsonQueryKeys.length : 0) +
      (comparisonResult.jsonPathKeys ? comparisonResult.jsonPathKeys.length : 0) +
      (comparisonResult.jsonKeys ? comparisonResult.jsonKeys.length : 0) +
      (comparisonResultHeader.jsonKeys ? comparisonResultHeader.jsonKeys.length : 0)
    );
  
    if (totalJsonKeysCount === 0) return "N/A";
  
    const totalMatchedKeysCount = (
      (comparisonResultHeader.excelKeys ? comparisonResultHeader.excelKeys.filter(keyObj => comparisonResultHeader.jsonKeys.some(jsonKey => jsonKey.key === keyObj.key)).length : 0) +
      (comparisonResult.excelKeys ? comparisonResult.excelKeys.filter(keyObj => comparisonResult.jsonKeys.some(jsonKey => jsonKey.key === keyObj.key)).length : 0) +
      (comparisonResult.excelQueryKeys ? comparisonResult.excelQueryKeys.filter(keyObj => comparisonResult.jsonQueryKeys.some(jsonKey => jsonKey.key === keyObj.key)).length : 0) +
      (comparisonResult.excelPathKeys ? comparisonResult.excelPathKeys.filter(keyObj => comparisonResult.jsonPathKeys.some(jsonKey => jsonKey.key === keyObj.key)).length : 0)
    );
  
    return ((totalMatchedKeysCount / totalJsonKeysCount) * 100).toFixed(2);
  };
  