import FileUploader from './FileUploader';
import '../styles/FileUploadAndCompare.css';
import React, { useState, useRef, useEffect } from 'react';
import { Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { MdRestartAlt } from "react-icons/md";

const FileUploadAndCompare = ({
    inputBaseUrl, setInputBaseUrl,
    excelFile, setExcelFile,
    jsonFile, setJsonFile,
    handleProcessFiles, handleReset, error
}) => {

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const videoRef = useRef(null);

    useEffect(() => {
        if (showModal && videoRef.current) {
          videoRef.current.play();
        } else if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      }, [showModal]);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Not sure where to find the baseUrl?
        </Tooltip>
    );

    const handleProcessFilesAndScroll = async () => {
        await handleProcessFiles();
        
        // Add a slight delay to ensure the DOM updates are complete (if necessary)
        setTimeout(() => {
            const element = document.getElementById('scrollTo');
            if (element) {
                console.log('Element found, scrolling to:', element);
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.log('Element not found');
            }
        }, 100); // Adjust the delay as needed
    };
    
    

    return (
        <>
            <h3 className="text-center pb-2 mt-3" style={{ color: '#FF7518'}}>Compare Your Data</h3>
            <div className="uploadsContainer">
            <div className='uploads'>
                {/* Base URL Input always on its own line */}
                    <div className="baseUrlContainer py-4">
                    <div className='baseUrl align-items-center'>
                    <div className="text-white">Provide &#123;&#123;baseUrl&#125;&#125;:</div>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                        >
                        <button
                            className="icon mt-0"
                            type="button"
                            onClick={handleShowModal}
                            ><BsFillQuestionSquareFill/>
                        </button>
                    </OverlayTrigger>
                    </div>
                    <input
                        type="text"
                        className="baseUrlForm form-control text-secondary"
                        placeholder="E.g. https://api.stripe.com/"
                        value={inputBaseUrl}
                        onChange={(e) => setInputBaseUrl(e.target.value.trim())}
                        />
                    </div>
                    <Modal show={showModal} onHide={handleCloseModal} onShow={() => videoRef.current.play()} size='lg'>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <video controls className="img-fluid" ref={videoRef} muted>
                                <source src="./Find base url v2.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Modal.Body>
                    </Modal>
                    {/* JSON File Upload */}
                    <div className="fileUpload pb-4">
                        <div className="text-white">Upload Postman Collection (JSON):</div>
                        <FileUploader
                            id="json-file"
                            className="fileUploadForm form-control text-secondary"
                            accept=".json"
                            onChange={(e) => setJsonFile(e.target.files[0])}
                            value={jsonFile ? jsonFile.name : ""}
                            onClear={() => setJsonFile(null)}
                        />
                    </div>
                    {/* Excel File Upload */}
                    <div className="fileUpload pb-4">
                        <div className="text-white">Upload APIDNA File (Excel):</div>
                        <FileUploader
                            id="excel-file"
                            className="fileUploadForm form-control text-secondary"
                            accept=".xlsx, .xls"
                            onChange={(e) => setExcelFile(e.target.files[0])}
                            value={excelFile ? excelFile.name : ""}
                            onClear={() => setExcelFile(null)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='compareButton'>
                    <button
                        style={{ backgroundColor: "#FF7518", borderColor: "#FF7518" }}
                        className="btn btn-primary compareButton"
                        onClick={handleProcessFilesAndScroll}
                        disabled={!excelFile || !jsonFile || !inputBaseUrl.trim()}
                    >
                        Compare
                    </button>
                </div>
            </div>
            {/* Reset Button */}
            <div className='text-center'>
                <button id='reset-file'
                    onClick={handleReset}
                    disabled={!excelFile && !jsonFile}
                    style={{
                        cursor: (!excelFile && !jsonFile) ? 'not-allowed' : 'pointer',
                        display: (!excelFile && !jsonFile) ? 'none' : ''
                    }}>
                    <MdRestartAlt className='resetIcon' />
                </button>
            </div>
            <div className="row">
                <div className="col-9 col-md-6 mx-auto mt-2 center-block text-center">
                    {error && (
                        <div className="error" role="alert"> <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-exclamation" class="svg-inline--fa fa-circle-exclamation " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="20"><path fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path></svg>
                            {error}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FileUploadAndCompare;
