// SearchBar.jsx
import React from 'react';
import '../styles/SearchBar.css'

const SearchBar = ({ searchTerm, onSearchChange }) => {
  return (
    <div>
      <input
        id="search"
        type="text"
        className="form-control search-bar"
        placeholder="Search by endpoint..."
        value={searchTerm}
        onChange={onSearchChange}
      />
    </div>
  );
};

export default SearchBar;
