import React from 'react';
import StatCard from './StatCard';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { FaInfoCircle } from 'react-icons/fa'; // Importing the info icon from react-icons

const StatsDisplay = ({ stats }) => {
  const { 
    totalExcelEndpoints, 
    totalJsonEndpoints, 
    missingCount, 
    matchRate,
    hallucinatedCount,
    unmatchedCount,
    totalMatching
  } = stats;

  // Calculate total of hallucinated and missing endpoints
  const totalUnmatchedEndpoints = hallucinatedCount + missingCount;

  // Check for discrepancy
  const hasDiscrepancy = totalExcelEndpoints + hallucinatedCount !== totalJsonEndpoints;

  const statCards = [
    { name: 'Total JSON Endpoints', figure: totalJsonEndpoints },
    { name: 'Total Excel Endpoints', figure: totalExcelEndpoints },
    { name: 'Total Matching Endpoints', figure: totalMatching },
    { name: 'Missing endpoints in the JSON', figure: hallucinatedCount, hasDiscrepancy },
    { name: 'Missing endpoints in the Excel', figure: missingCount },
    { name: 'Total Unmatched Endpoints', figure: totalUnmatchedEndpoints },
    { 
      name: 'Match Rate', 
      figure: isNaN(matchRate) ? '0.00%' : `${matchRate.toFixed(2)}%`,
      tooltip: 'Match Rate is calculated as:\n(Total Matching Endpoints / Total JSON Endpoints) * 100'
    },
  ];

  return (
    <div className='container'>
      <h3 className="mt-5" style={{ color: '#FF7518'}}>Summary</h3>
      <div className="row d-flex justify-content-center mt-5">
        {statCards.map((card, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 mb-5" style={{ position: 'relative' }}>
            <StatCard name={card.name} figure={card.figure} />
            {card.name === 'Missing JSON Endpoints in Excel' && (
              <>
                <FaInfoCircle 
                  data-tooltip-id={`info-tooltip-${index}`} 
                  style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer', color: card.hasDiscrepancy ? 'black' : 'transparent' }} 
                  size={20}
                />
                <Tooltip id={`info-tooltip-${index}`} place="top" effect="solid" className='custom-tooltip'>
                  {card.hasDiscrepancy && (
                    <>
                      Duplicate endpoints and HTTP methods<br />
                      may not show in the Excel file or be<br />
                      counted as a missing endpoint in Excel.
                    </>
                  )}
                </Tooltip>
              </>
            )}
            {card.name === 'Match Rate' && (
              <>
                <FaInfoCircle 
                  data-tooltip-id={`info-tooltip-match-rate-${index}`} 
                  style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer', color: 'black' }} 
                  size={20}
                />
                <Tooltip id={`info-tooltip-match-rate-${index}`} place="top" effect="solid" className='custom-tooltip'>
                  {card.tooltip}
                </Tooltip>
              </>
            )}
            {card.name === 'Hallucinated Excel Endpoints' && (
              <>
                <FaInfoCircle 
                  data-tooltip-id={`info-tooltip-hallucinated-${index}`} 
                  style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer', color: 'black' }} 
                  size={20}
                />
                <Tooltip id={`info-tooltip-hallucinated-${index}`} place="top" effect="solid" className='custom-tooltip'>
                  {card.tooltip}
                </Tooltip>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsDisplay;
