// utils/extractRawUrlAndMethods.js
export const extractRawUrlAndMethods = (jsonObject, inputBaseUrl) => {
  const urlsAndMethods = [];
  const userUrl = inputBaseUrl

  const removeQueryParams = (url) => {
    return url.split('?')[0];
  }

  const extractEndpoint = (url) => {
    const versionPattern = /\/(api\/v\d+|v\d+)\//;
    const match = url.match(versionPattern);
    if (match) {
      const [matchedVersion] = match;
      return url.split(matchedVersion)[1];
    } else {
      return null;
    }
  };

  const findUrlsAndMethods = (obj) => {
    let requestUrlAndMethod = null;
    let requestEndpoint = null;

    if (obj.request && obj.request.url && obj.request.method) {
      const cleanedUrl = removeQueryParams(obj.request.url.raw);
      requestEndpoint = extractEndpoint(cleanedUrl);
      requestUrlAndMethod = { rawUrl: cleanedUrl, method: obj.request.method, endpoint: requestEndpoint };
      urlsAndMethods.push(requestUrlAndMethod);
    }

    if (obj.response && Array.isArray(obj.response)) {
      obj.response.forEach(responseItem => {
        if (responseItem.originalRequest && responseItem.originalRequest.url && responseItem.originalRequest.method) {
          const cleanedUrl = removeQueryParams(responseItem.originalRequest.url.raw);
          const responseEndpoint = extractEndpoint(cleanedUrl);

          const responseUrlAndMethod = { rawUrl: cleanedUrl, method: responseItem.originalRequest.method, endpoint: responseEndpoint };
          const isDuplicate = urlsAndMethods.some(item => item.endpoint === responseUrlAndMethod.endpoint && item.method === responseUrlAndMethod.method);
          if (!isDuplicate) {
            urlsAndMethods.push(responseUrlAndMethod);
          }
        }
      });
    }

    if (obj.item && Array.isArray(obj.item)) {
      for (const subItem of obj.item) {
        findUrlsAndMethods(subItem);
      }
    }
  };

  findUrlsAndMethods(jsonObject);

  // Replaces {{baseUrl}} with users' inputted url
  const updatedUrlsAndMethods = urlsAndMethods.map(item => {
    return {
      ...item,
      rawUrl: item.rawUrl.replace(/\{\{.*?\}\}/g, userUrl)
    };
  });

  return updatedUrlsAndMethods.map(({ endpoint, ...rest }) => rest); // Remove the endpoint key from the final output
};