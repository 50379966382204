import React from 'react';
import SimpleSlider from './SimpleSlider';
import '../styles/hero.css';

const styles = {

  headerSubtitle: {
    fontWeight: 'bold'
  },
  subHeroContainer: {
    background: '#FFFFFF',
    width: '100%',
    },
  subHeroInner:{
    maxWidth: '1440px',
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
  },
  subHeroTextWrapper: {
    color: 'black',
  },

  subHeroText: {
    fontSize: '18px'
  },
  subHeroWrapper: {
    width: '100%',
  },

}

const Hero = () => {
    return (
        <section className="hero-section">
          <div className="section--wrapper content--container hero--container" >
            <h1 className="headerTitle">API Integration Validator</h1>
            <h2 style={styles.headerSubtitle} className="headerSubTitle">Check the accuracy and transparency of our AI user agent now</h2>
            <p className="sub-text">API source data with our AI-generated data side-by-side, addressing any concerns about potential inaccuracies.</p>
          </div>
          <div style={styles.subHeroWrapper}>
            <div style={styles.subHeroContainer}>
              <div style={styles.subHeroInner} className="subHero--inner">
                <div style={{...styles.subHeroTextWrapper}}className="subHeroText--wrapper text-content subHero--item">
                  <h2 className="subHeroTitle">Transparency is the key to accuracy</h2>
                  <p style={styles.subHeroText}>Understand how accurate the AI-generated data is, and rest assured that we don't hide errors but show the actual results if the data is incorrect. We also highlight any instances of data inflation or inaccurate data creation to ensure complete transparency</p>
                </div>
                <div className="subHero--item">
                  <SimpleSlider />
                </div>
              </div>
            </div>
          </div>
        </section>
    );
};

export default Hero;
