export const parseQueryContent = (lines, startIndex) => {
    const parameters = {};
    const queryPattern = /Query Parameters:/;
    const typePattern = /Type=/;
    const pathPattern = /Path Parameters:/;
  
    let pathIndex = -1;
  
    // Find the start index of the path parameters section
    for (let i = startIndex; i < lines.length; i++) {
      const line = lines[i].trim();
      if (pathPattern.test(line)) {
        pathIndex = i;
        break;
      }
    }
  
    // Iterate over each line starting from the provided index until the path parameters section
    for (let i = startIndex; i < pathIndex; i++) {
      const line = lines[i].trim();
  
      // Check if the line contains a key-value pair and process it
      if (typePattern.test(line)) {
        const keyValue = line.split(": Type=");
        if (keyValue.length === 2) {
          const key = keyValue[0]?.trim(); // Get the parameter name
          const value = keyValue[1]?.split(",")[0]?.trim(); // Extract type value
  
          // Store the key in the appropriate section (Query)
          parameters[`Query[${key}]`] = value;
        }
      }
    }
  
    // Extract all keys
    const keys = Object.keys(parameters);
    console.log(keys, "keys from parseQueryContent");
    return keys;
  };
  