export const parsePathContent = (lines, startIndex) => {
  const parameters = {};

  // Regular expression pattern to match the type section
  const typePattern = /Type=/;

  // Iterate over each line starting from the provided index
  for (let i = startIndex; i < lines.length; i++) {
      const line = lines[i].trim(); // Trim the line to remove leading and trailing spaces

      // Check if the line contains a key-value pair and process it
      if (typePattern.test(line)) {
          const keyValue = line.split(": Type=");
          if (keyValue.length === 2) {
              const key = keyValue[0]?.trim(); // Get the parameter name
              const value = keyValue[1]?.split(",")[0]?.trim(); // Extract type value

              // Store the key in the appropriate section (Path)
              parameters[`Path[${key}]`] = value;
          }
      }
  }

  // Extract all keys
  const keys = Object.keys(parameters);

  return keys;
};
