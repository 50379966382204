import React, { useState, useEffect } from "react";
import {
    readExcelFile,
    readJsonFile,
    HeadBodyModal,
    extractJsonBody,
    parseBodyContent,
    extractJsonHeader,
    parseHeaderContent,
    extractRawUrlAndMethods,
} from './utils'
import {
    FileUploader,
    StatsDisplay,
    FilterDropdown,
    PageSizeDropdown,
    EndpointTable,
    Pagination,
    Navbar,
    Hero,
    SearchBar,
    MethodFilter,
    Footer,
    FileUploadAndCompare,
    AccessWall,
    FAQs
} from './components'; // Adjust import paths as per your project structure
import performBodyComparison from "./utils/performBodyComparison";
import performHeaderComparison from "./utils/performHeaderComparison";
import { Tooltip } from 'react-tooltip';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-tooltip/dist/react-tooltip.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Exported variable and setter
export let inputBaseUrl = '';
export const setInputBaseUrl = (url) => {
    inputBaseUrl = url;
};
export const getInputBaseUrl = () => inputBaseUrl;

const App = () => {
    const [localInputBaseUrl, setLocalInputBaseUrl] = useState('');
    const [comparisonResult, setComparisonResult] = useState({});
    const [comparisonResultHeader, setComparisonResultHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [endpoints, setEndpoints] = useState([]);
    const [endpointsPerPage, setEndpointsPerPage] = useState(20);
    const [excelFile, setExcelFile] = useState(null);
    const [filesProcessed, setFilesProcessed] = useState(false);
    const [filter, setFilter] = useState('All');
    const [methods, setMethods] = useState([]);
    const [filteredEndpoints, setFilteredEndpoints] = useState([]);
    const [jsonFile, setJsonFile] = useState(null);
    const [matchRate, setMatchRate] = useState(0);
    const [missingCount, setMissingCount] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEndpoint, setSelectedEndpoint] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [totalEndpoints, setTotalEndpoints] = useState(0);
    const [totalMatching, setTotalMatching] = useState(0);
    const [totalExcelEndpoints, setTotalExcelEndpoints] = useState(0);
    const [totalJsonEndpoints, setTotalJsonEndpoints] = useState(0);
    const [hallucinatedCount, setHallucinatedCount] = useState(0);
    const [unmatchedCount, setUnmatchedCount] = useState(0);
    const [hasDiscrepancy, setHasDiscrepancy] = useState(false);
    const pageSizeOptions = [20, 50, 100, 'All'];
    const [error, setError] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Check localStorage for authentication state
        const authState = localStorage.getItem('isAuthenticated');
        if (authState === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const getExcelFirstUrl = (excelData) => {
        if (excelData && excelData[1] && excelData[1][3]) {
            return excelData[1][3]; // URL
        }

        return '';
    };

    const boldEndpoint = (url) => {
        if (url) {
          const versionPattern = /\/(api\/v\d+|v\d+)\//;
          const match = url.match(versionPattern);
          if (match) {
            const [matchedVersion] = match;
            const parts = url.split(matchedVersion);
            if (parts[1]) {
              return <strong>{parts[1]}</strong>;
            }
          }
          return <span style={{ color: 'red' }}>{url}</span>;
        }
        return <span>N/A</span>; // Return a span with 'N/A' if url is null or undefined
      };      

    useEffect(() => {
        const calculateStats = () => {
            // Total endpoints from the Excel file
            const totalExcelEndpoints = endpoints.filter(endpoint => endpoint.method !== 'N/A').length;
            // console.log("total excel", totalExcelEndpoints)
            // Total endpoints from the JSON file
            const totalJsonEndpoints = endpoints.filter(endpoint => endpoint.jsonMethod !== 'N/A').length;
            // console.log("total json", totalJsonEndpoints)
    
            // Matching endpoints (endpoints that exist in both Excel and JSON)
            const matchingEndpoints = endpoints.filter(endpoint => endpoint.existsInJson);
            // console.log("total matching", matchingEndpoints)
    
            // Hallucinated endpoints (exist in Excel but not in JSON)
            const existsInExcel = endpoints.filter(endpoint => !endpoint.existsInJson && endpoint.rationale === 'Missing in JSON');
            // console.log("total exists in excel", existsInExcel)
    
            // Missing in JSON endpoints (exist in JSON but not in Excel)
            const existsinJson = endpoints.filter(endpoint => !endpoint.existsInExcel && endpoint.rationale === 'Exists only in JSON');
            // console.log("total exists in json", existsinJson)
    
            // Total matching endpoints
            const totalMatching = matchingEndpoints.length;
            // console.log("total matching", totalMatching)
            // Total hallucinated endpoints
            const totalExistsInExcel = existsInExcel.length;
            // console.log("total exists in excel", totalExistsInExcel)
            // Total missing in JSON endpoints
            const totalExistsInJson = existsinJson.length;
            // console.log("total exists in json", totalExistsInJson)
            // Total unmatched endpoints (sum of hallucinated and missing in JSON endpoints)
            const totalUnmatched = totalExistsInExcel + totalExistsInJson;
            // console.log("total unmatched", totalUnmatched)
    
            // Calculate match rate (percentage of Excel endpoints found in JSON)
            const calculatedMatchRate = (totalMatching / totalJsonEndpoints) * 100;
            // console.log("calculate match rate", calculatedMatchRate)
        
            // Set calculated statistics
            setTotalExcelEndpoints(totalExcelEndpoints);
            setTotalJsonEndpoints(totalJsonEndpoints);
            setMatchRate(calculatedMatchRate);
            setHallucinatedCount(totalExistsInExcel);
            setMissingCount(totalExistsInJson);
            setUnmatchedCount(totalUnmatched);
            setTotalMatching(totalMatching);
    
            // Check for discrepancy
            const hasDiscrepancy = totalExcelEndpoints + totalExistsInJson !== totalJsonEndpoints;
            setHasDiscrepancy(hasDiscrepancy);
        };
    
        calculateStats();
    }, [endpoints]);

    useEffect(() => {
        let filtered = endpoints.filter((endpoint) => {
            const urlMatch =
                endpoint.url &&
                endpoint.url.toLowerCase().includes(searchTerm.toLowerCase());
            const methodMatch =
                methods.length === 0 || methods.includes(endpoint.method);
            return urlMatch && methodMatch;
        });
    
        if (filter === 'Matching') {
            filtered = filtered.filter((endpoint) => endpoint.existsInJson);
        } else if (filter === 'Not Matching') {
            filtered = filtered.filter((endpoint) => !endpoint.existsInJson);
        }
    
        setFilteredEndpoints(filtered);
    }, [searchTerm, filter, methods, endpoints]);
    

    const handleFileChange = (fileSetter) => (e) => {
        fileSetter(e.target.files[0]);
    };

    const handleClearFile = (fileSetter) => () => {
        fileSetter(null);
    };

    const resetStats = () => {
        setTotalExcelEndpoints(0);
        setTotalJsonEndpoints(0);
        setMissingCount(0);
        setMatchRate(0);
    };

    const resetSearchBar = () => {
        setSearchTerm('');
    };

    const resetMethodFilter = () => {
        setMethods([]);
    };

    const resetFilterDropdown = () => {
        setFilter('All');
    };

    const resetPageSizeDropdown = () => {
        setEndpointsPerPage(20);
        setCurrentPage(1);
    };

    const resetPagination = () => {
        setCurrentPage(1);
        setEndpointsPerPage(20);
    };

    const resetEndpointTable = () => {
        setEndpoints([]);
        setFilteredEndpoints([]);
        setCurrentPage(1);
        setSortDirection('asc');
    };

    const resetBeforeCompare = () => {
        resetStats();
        resetSearchBar();
        resetMethodFilter();
        resetFilterDropdown();
        resetPageSizeDropdown();
        resetEndpointTable();
        setError('');
        resetPagination();
        setFilesProcessed(false);
    };    

    const handleReset = () => {
        resetStats();
        resetSearchBar();
        resetMethodFilter();
        resetFilterDropdown();
        resetPageSizeDropdown();
        resetEndpointTable();
        setJsonFile(null);
        setExcelFile(null);
        setInputBaseUrl('');  // Reset the user input base URL
        setLocalInputBaseUrl('');  // Also reset the local state
        setError('');
        resetPagination();
    };   
    
    const extractBaseDomain = (url) => {
        // We should be able to cover following URL cases:
        // https://stripe.api.com/v1 => stripe.com
        // https://google.com/v1/account =>  google.com
        // www.stripe.com/v1/ => stripe.com
        // http://www.example.com => example.com
        // https://api.stripe.com => stripe.com
        // https://mail.google.com => google.com
        // https://api.datadoghq.comhttps://{{subdomain}}.{{site}}/api/v2/apm/config/retention-filters => datadoghq.com

        // Helper function to check if a string is a valid URL
        const isValidURL = (str) => {
            try {
                new URL(str);
                return true;
            } catch (e) {
                return false;
            }
        };
    
        // Extract the primary URL
        const urlParts = url.split(/https?:\/\//).filter(part => part);
        let primaryUrl = urlParts.find(part => isValidURL('http://' + part) || isValidURL('https://' + part));
        
        if (!primaryUrl) {
            console.error("Invalid URL provided:", url);
            return null;
        }
    
        primaryUrl = 'http://' + primaryUrl.split('/')[0]; // Ensure we only get the hostname part
        
        try {
            // Parse the URL
            const parsedUrl = new URL(primaryUrl);
            
            // Extract the hostname
            let hostname = parsedUrl.hostname;
            
            // Remove 'www.' if it exists
            if (hostname.startsWith('www.')) {
                hostname = hostname.substring(4);
            }
            
            // Split the hostname into segments
            const parts = hostname.split('.');
            
            // If the hostname has more than two parts, remove the subdomain(s)
            if (parts.length > 2) {
                hostname = parts.slice(-2).join('.');
            }
            
            return hostname;
        } catch (error) {
            console.error("Failed to parse URL:", primaryUrl);
            return null;
        }
    };  

    const handleProcessFiles = async () => {
        resetBeforeCompare();

        if (!excelFile || !jsonFile || !localInputBaseUrl) {
            setError(' Please enter a base URL and upload both Excel and JSON files.');
            return;
        }

        if (!(localInputBaseUrl.startsWith('https://') || localInputBaseUrl.startsWith('http://'))) {
            setError(' The base URL must begin with "https://" or "http://"');
            return;
        }

        setError(''); // clears error when all parameters are met
        setInputBaseUrl(localInputBaseUrl); // Update the exported value

        try {
            const [excelData, jsonData] = await Promise.all([
                readExcelFile(excelFile),
                readJsonFile(jsonFile),
            ]);

            const excelFirstUrl = getExcelFirstUrl(excelData);
            const baseDomainExcelUrl = extractBaseDomain(excelFirstUrl);
            const baseDomainInputUrl = extractBaseDomain(localInputBaseUrl);
    
            if (baseDomainExcelUrl !== baseDomainInputUrl) {
                setError(`Please enter a base url that is matching a base domain name '${baseDomainExcelUrl}' from the excel file.`);
                return;
            }            

            const extractEndpoint = (url) => {
                const versionPattern = /\/(api\/v\d+|v\d+)\//;
                const match = url.match(versionPattern);
                if (match) {
                    const [matchedVersion] = match;
                    return url.split(matchedVersion)[1]?.split('?')[0];
                } else {
                    return null;    
                }
            };

            const jsonUrlsAndMethods = extractRawUrlAndMethods(jsonData, localInputBaseUrl);
            

            const findExactJsonEndpoint = (url, method) => {
                return jsonUrlsAndMethods.find(
                    (endpoint) => {
                        const dynamicUrl = extractEndpoint(endpoint.rawUrl.replace("{{baseUrl}}", localInputBaseUrl));
                        return dynamicUrl === url && endpoint.method.toLowerCase() === method.toLowerCase();
                    }
                );
            };

            const excelEndpoints = excelData.slice(1).map((row) => {
                const rawUrl = row[3] ? row[3].split('?')[0] : null;
                const dynamicExcelUrl = rawUrl ? extractEndpoint(rawUrl) : null;
                const method = row[4];
                const jsonEndpoint = dynamicExcelUrl ? findExactJsonEndpoint(dynamicExcelUrl, method) : null;

                return {
                    url: rawUrl,
                    method: method,
                    jsonUrl: jsonEndpoint ? jsonEndpoint.rawUrl : null,
                    jsonMethod: jsonEndpoint ? jsonEndpoint.method : 'N/A',
                    existsInJson: !!jsonEndpoint,
                    rationale: jsonEndpoint ? 'Match' : 'Missing in JSON',
                };
            });

            const combinedEndpoints = excelEndpoints.concat(
                jsonUrlsAndMethods
                    .filter(
                        (jsonEndpoint) => !excelEndpoints.some(
                            (ep) => {
                                const dynamicExcelUrl = ep.url ? extractEndpoint(ep.url) : null;
                                const dynamicJsonUrl = extractEndpoint(jsonEndpoint.rawUrl.replace("{{baseUrl}}", localInputBaseUrl));
                                return dynamicExcelUrl === dynamicJsonUrl;
                            }
                        )
                    )
                    .map((jsonEndpoint) => ({
                        url: jsonEndpoint.rawUrl,
                        method: 'N/A',
                        jsonUrl: jsonEndpoint.rawUrl,
                        jsonMethod: jsonEndpoint.method,
                        existsInJson: false,
                        rationale: 'Exists only in JSON',
                    }))
            );

            setEndpoints(combinedEndpoints);
            setFilteredEndpoints(combinedEndpoints);
            setTotalExcelEndpoints(excelEndpoints.length);
            setTotalJsonEndpoints(jsonUrlsAndMethods.length);
            setFilesProcessed(true);
            setError('');
        } catch (error) {
            console.error('Error:', error);
            setError(`${error}`);
        }
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const handleMethodChange = (newMethods) => {
        setMethods(newMethods);
    };

    const handleEndpointsPerPageChange = (newSize) => {
        setEndpointsPerPage(
            newSize === 'All' ? endpoints.length : parseInt(newSize, 10)
        );
        setCurrentPage(1);
    };

    const handleSortChange = () => {
        const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        const sortedEndpoints = [...filteredEndpoints].sort((a, b) => {
            if (newDirection === 'asc') {
                return a.url.localeCompare(b.url);
            } else {
                return b.url.localeCompare(a.url);
            }
        });
        setFilteredEndpoints(sortedEndpoints);
        setSortDirection(newDirection);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastEndpoint = currentPage * endpointsPerPage;
    const indexOfFirstEndpoint = indexOfLastEndpoint - endpointsPerPage;
    const currentEndpoints = filteredEndpoints.slice(
        indexOfFirstEndpoint,
        indexOfLastEndpoint
    );

    const handleEndpointClick = async (endpoint) => {
        const endpointTitle = boldEndpoint(endpoint.url); // Extract bolded endpoint
    
        setSelectedEndpoint({ ...endpoint, boldedEndpoint: endpointTitle });
    
        try {
            const bodyComparison = await performBodyComparison(endpoint, jsonFile, excelFile, endpoint.method);
            const headerComparison = await performHeaderComparison(endpoint, jsonFile, excelFile, endpoint.method);
    
            let parsedBodyComparison;
            let parsedHeaderComparison;
    
            try {
                parsedBodyComparison = JSON.parse(bodyComparison);
            } catch (e) {
                parsedBodyComparison = { error: 'Invalid body comparison data' };
            }
    
            try {
                parsedHeaderComparison = JSON.parse(headerComparison);
            } catch (e) {
                parsedHeaderComparison = { error: 'Invalid header comparison data' };
            }
    
            setComparisonResult(parsedBodyComparison);
            setComparisonResultHeader(parsedHeaderComparison);
        } catch (error) {
            console.error('Error performing comparisons:', error);
            setComparisonResult({ error: 'An error occurred during comparison.' });
        }
    
        setModalVisible(true);
    };
    
    // Close modal without resetting the current page
    const handleCloseModal = () => {
        setModalVisible(false);
    };
    

    const handleAuthenticated = (authState) => {
        setIsAuthenticated(authState);
        localStorage.setItem('isAuthenticated', authState);
    };

    const handleUpdateOverallMatchRate = (endpoint, overallMatchRate) => {
        setEndpoints((prevEndpoints) =>
          prevEndpoints.map((ep) =>
            ep.url === endpoint.url ? { ...ep, overallMatchRate } : ep
          )
        );
      };

    return (
        <div className="d-flex flex-column min-vh-100">
            {!isAuthenticated ? (
                <AccessWall onAuthenticated={handleAuthenticated} />
            ) : (
                <>
                    <Navbar />
                    <Hero />
                    <div className="container my-5">
                        <FileUploadAndCompare
                            inputBaseUrl={localInputBaseUrl}
                            setInputBaseUrl={setLocalInputBaseUrl}
                            excelFile={excelFile}
                            setExcelFile={setExcelFile}
                            jsonFile={jsonFile}
                            setJsonFile={setJsonFile}
                            handleProcessFiles={handleProcessFiles}
                            handleReset={handleReset}
                            error={error}
                        />
                    </div>
                    {filesProcessed && (
                        <div className='flex-grow-1'>
                            <div className='row mb-5'>
                                <div className='col-12' id="scrollTo" style={{ background: "#171a1e"}}>
                                    <StatsDisplay
                                        stats={{
                                            totalExcelEndpoints,
                                            totalJsonEndpoints,
                                            matchRate,
                                            missingCount,
                                            hallucinatedCount,
                                            unmatchedCount,
                                            totalMatching
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='container my-5'>
                                <div className='row d-flex justify-content-between mb-5'>
                                    <h3 className='mb-5' style={{ color: '#FF7518' }}>Comparison Detail</h3>
                                    
                                    <div className='col-sm-12 col-md-4 d-flex align-items-center'>
                                        <div className='me-3'>
                                            <MethodFilter
                                                methods={methods}
                                                handleMethodChange={handleMethodChange}
                                            />
                                        </div>
                                        <div className='me-3' style={{ width: "150px"}}>
                                            <FilterDropdown
                                                filter={filter}
                                                handleFilterChange={handleFilterChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-sm-12 col-md-8 d-flex justify-content-end align-items-center'>
                                        <div className='me-3' style={{ width: "400px", marginTop: "20px"}}>
                                            <SearchBar
                                                searchTerm={searchTerm}
                                                onSearchChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                        </div>
                                        <div style={{ width: "150px"}}>
                                            <PageSizeDropdown
                                                pageSize={endpointsPerPage}
                                                onPageSizeChange={handleEndpointsPerPageChange}
                                                pageSizeOptions={pageSizeOptions}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='container-fluid' style={{ width: '90%' }}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <EndpointTable
                                            endpoints={currentEndpoints}
                                            onEndpointClick={handleEndpointClick}
                                            handleSortChange={handleSortChange}
                                            sortDirection={sortDirection}
                                            boldEndpoint={boldEndpoint}
                                        />
                                    </div>
                                    <div className='col-12'>
                                        <Pagination
                                            currentPage={currentPage}
                                            itemsPerPage={endpointsPerPage}
                                            totalItems={filteredEndpoints.length}
                                            onPageChange={paginate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {modalVisible && (
                        <HeadBodyModal
                            show={modalVisible}
                            onHide={handleCloseModal}
                            endpoint={selectedEndpoint}
                            comparisonResult={comparisonResult}
                            comparisonResultHeader={comparisonResultHeader}
                            jsonFile={jsonFile}
                            excelFile={excelFile}
                        />
                    )}
                    <FAQs />
                    <Footer />
                </>
            )}
        </div>
    );
};

export default App;
