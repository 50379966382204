import { getInputBaseUrl } from "../App";

export const parseHeaderContent = (lines, startIndex, targetUrl, targetMethod) => {
  const headers = {};

  // Adjust the regex pattern to match headers with two-space indentation
  const headerPattern = /^\s{2}([^:]+):\s*(\{.*\})$/;
const targetMethodHeader = targetMethod.toLowerCase();
// console.log(targetMethodHeader, "targetMethod from parseHeaderContent");
  // Extract the URL from the log lines and apply filtering logic
  const urlLine = lines.find((line) => line.includes("Endpoint URL"));
  if (!urlLine) {
    throw new Error("Endpoint URL not found in log lines");
  }
  const methodLine = lines.find((line) => line.includes("HTTP Method"));
  const url = urlLine.split("Endpoint URL: ")[1];
  const baseUrl = getInputBaseUrl();
  const removeQueryParams = (url) => {
    return url.split('?')[0];
  }

  const jsonUrlQuery = url.replace(/{{baseUrl}}|{{base_url}}/, baseUrl);
const filteredUrl = removeQueryParams(jsonUrlQuery);
const myMethodUpperCase = methodLine.split("HTTP Method: ")[1];
const myMethod = myMethodUpperCase.toLowerCase();


  // Iterate through lines starting from startIndex
  for (let i = startIndex; i < lines.length; i++) {
    const line = lines[i];

    // Check if the filteredUrl matches the targetUrl
    if (filteredUrl === targetUrl && myMethod === targetMethodHeader ) {
      if (line.startsWith("  ")) {
        const match = line.match(headerPattern);
        if (match) {
          const key = match[1]?.trim(); // Extract the header key
          const value = match[2]?.trim(); // Extract the header value
          headers[key] = value; // Store the header key-value pair
        }
      } else {
        // Handle invalid header lines if necessary
      }
    } else {
      break; // Exit loop if filteredUrl doesn't match targetUrl
    }
  }

  return headers;
};
