import React, { useState } from 'react';
import { BsPaperclip } from 'react-icons/bs';
import { AiFillCloseSquare } from "react-icons/ai";
import '../styles/FileUploadAndCompare.css';

const FileUploader = ({ id, label, accept, onChange, value, onClear }) => {
  return (
    <div>
      <div className="file-input-container">
        <label htmlFor={id} className="form-label">{label}</label>
        <div className="input-group">
          {!value && (
            <>
            <input
                type="file"
                id={id}
                className="form-control"
                style={{ display: 'none' }}
                accept={accept}
                onChange={onChange}
            />
            <button
                type="button"
                className="uploadButton"
                onClick={() => document.getElementById(id).click()}
            > <div className='uploadButtonBox'>
              <p className='noFileChosen'>No file chosen</p>
              <BsPaperclip />
              </div>
            </button>
        </>
          )}
          {value && (
            <div className="">
              <span className="file-name me-5" >{value}</span>
              <button
                type="button"
                className="btn btn-sm mt-0"
                onClick={onClear}
              >
                <AiFillCloseSquare className='icon'/>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploader;