import { readJsonFile, readExcelFile, extractJsonBody, parseBodyContent } from '../utils';
import { parseQueryContent } from './parseQueryContent';
import { parsePathContent } from './parsePathContent';
import { extractJsonPathQuery } from './extractJsonPathQuery';
import { getInputBaseUrl } from "../App";

const performBodyComparison = async (endpoint, jsonFile, excelFile, method) => {
  try {
    const jsonData = await readJsonFile(jsonFile);
    const excelData = await readExcelFile(excelFile);
    const url = endpoint.url;
    const baseUrl = getInputBaseUrl();
    const removeQueryParams = (url) => {
      if (!url) return '';
      return url.includes('?') ? url.split('?')[0] : url;
    };
    const jsonUrlQuery = url.replace(/{{baseUrl}}|{{base_url}}/, baseUrl);
    const targetUrl = removeQueryParams(jsonUrlQuery);
    const targetMethod = method;
    const jsonBodyData = extractJsonBody(jsonData, targetUrl, targetMethod);
    const jsonPathQueryData = extractJsonPathQuery(jsonData, targetUrl, targetMethod);

    const excelBodyContentKeysByEndpoint = {};
    const excelQueryKeysByEndpoint = {};
    const excelPathKeysByEndpoint = {};

    excelData.forEach((row) => {
      const endpointUrl = removeQueryParams(row[3]);
      const method = row[4];
      const apiDocumentation = row[5];
      
      if (endpointUrl === targetUrl && method === targetMethod && apiDocumentation) {
        const documentationLines = apiDocumentation.split("\n");
    
        documentationLines.forEach((line, index) => {
            const key = `${endpointUrl},${method}`;
    
            if (line.startsWith("Body Content:")) {
                const bodyContentKeys = parseBodyContent(documentationLines, index + 1);
                excelBodyContentKeysByEndpoint[key] = excelBodyContentKeysByEndpoint[key]
                    ? excelBodyContentKeysByEndpoint[key].concat(bodyContentKeys)
                    : bodyContentKeys;
            } else if (line.startsWith("Path Parameters:")) {
              if (!line.includes("None specified")) {
                const pathContentKeys = parsePathContent(documentationLines, index + 1);
    
                if (!excelPathKeysByEndpoint[key]) {
                    excelPathKeysByEndpoint[key] = [];
                }
    
                excelPathKeysByEndpoint[key] = excelPathKeysByEndpoint[key].concat(pathContentKeys);
            } }else if (line.startsWith("Query Parameters:")) {
              if (!line.includes("None specified")) {
                const queryContentKeys = parseQueryContent(documentationLines, index + 1);
    
                if (!excelQueryKeysByEndpoint[key]) {
                    excelQueryKeysByEndpoint[key] = [];
                }
    
                excelQueryKeysByEndpoint[key] = excelQueryKeysByEndpoint[key].concat(queryContentKeys);
            } }
        });
    }
    
    });

    const jsonKeys = jsonBodyData?.body?.map((item) => item.key) || [];
    const jsonKeysWithDetails = jsonKeys;
    const jsonQueryKeys = jsonPathQueryData?.query ? Object.keys(jsonPathQueryData.query) : [];
    const jsonPathKeys = jsonPathQueryData?.path || [];
const importantPathKeys = []; // Adding only the path keys with ":" in front
    // Logic for removing the colon in the path keys and returning only them
    if (jsonPathKeys.length > 0) {
      jsonPathKeys.forEach((pathKey, index) => {
        if (pathKey.startsWith(':')) {
          const cleanedPathKey = jsonPathKeys[index] = pathKey.slice(1);
          importantPathKeys.push(cleanedPathKey);
        }
      });
    }
  
    // Extract and deduplicate the keys for the target URL and method
    const key = `${targetUrl},${targetMethod}`;
    const excelKeysWithDetails = excelBodyContentKeysByEndpoint[key] || [];
    const uniqueExcelKeys = [...new Set(excelKeysWithDetails.map((key) => key.split("[")[0]))];
    const uniqueJsonKeys = [...new Set(jsonKeysWithDetails.map((key) => key.split("[")[0]))];
    const excelQueryKeysWithDetails = excelQueryKeysByEndpoint[key] || [];
    const excelPathKeysWithDetails = excelPathKeysByEndpoint[key] || [];
    

    
    // Function to extract the key from within the brackets
    const extractKey = (key) => {
        const start = key.indexOf('[') + 1;
        const end = key.lastIndexOf(']');
        return start > 0 && end > start ? key.substring(start, end) : undefined;
    };
    
    const excelQueryKeysWithArray = excelQueryKeysWithDetails.map(extractKey).filter(key => key !== undefined);
    const excelPathKeysWithArray = excelPathKeysWithDetails.map(extractKey).filter(key => key !== undefined);
    
    const excelQueryKeys = [];
    excelQueryKeysWithArray.forEach((key) => {
        if (key) {
            excelQueryKeys.push(key);
        }
    });
    
    const excelPathKeys = [];
    excelPathKeysWithArray.forEach((key) => {
        if (key) {
            excelPathKeys.push(key);
        }
    });
    



const matchedPathKeys = excelPathKeys.filter((pathKey) => importantPathKeys.includes(pathKey));
const matchedQueryKeys = excelQueryKeys.filter((queryKey) => jsonQueryKeys.includes(queryKey));
    const matchedKeys = uniqueExcelKeys.filter((excelKey) => uniqueJsonKeys.includes(excelKey));

const highlightedPathExcelKeys = excelPathKeys.map((key) => ({
    key,
    highlight:!matchedPathKeys.includes(key),
}));

const highlightedQueryExcelKeys = excelQueryKeys.map((key) => ({
    key,
    highlight:!matchedQueryKeys.includes(key),
}));

const highlightedPathJsonKeys = importantPathKeys.map((key) => ({
    key,
    highlight:!matchedPathKeys.includes(key),
}));

const highlightedQueryJsonKeys = jsonQueryKeys.map((key) => ({
    key,
    highlight:!matchedQueryKeys.includes(key),
}));

    const highlightedJsonKeys = uniqueJsonKeys.map((key) => ({
      key,
      highlight: !matchedKeys.includes(key),
    }));

    const highlightedExcelKeys = uniqueExcelKeys.map((key) => ({
      key,
      highlight: !matchedKeys.includes(key),
    }));

    const comparisonResult = {
      jsonKeys: highlightedJsonKeys,
      excelKeys: highlightedExcelKeys,
      jsonPathKeys: highlightedPathJsonKeys,
      excelPathKeys: highlightedPathExcelKeys,
      jsonQueryKeys: highlightedQueryJsonKeys,
      excelQueryKeys: highlightedQueryExcelKeys,
      matchedKeys: matchedKeys.map((key) => ({ key })),
      matchCount: matchedKeys.length,
    };

    return JSON.stringify(comparisonResult, null, 2);
  } catch (error) {
    console.error("Comparison Error:", error);
    return "An error occurred during comparison.";
  }
};

export default performBodyComparison;

