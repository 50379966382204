import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/AccessWall.css'; // Import the CSS file for styling
import { ReactComponent as Logo } from "../logo.svg";

const AccessWall = ({ onAuthenticated }) => {
  const [password, setPassword] = useState('');

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const correctPassword = process.env.REACT_APP_PASSWORD; // Retrieve the password from environment variables

    // eslint-disable-next-line no-undef
    const correctPassword = typeof MyAppSettings !== 'undefined' ? MyAppSettings.REACT_APP_PASSWORD : 'defaultPassword';
    // changed to MyAppSettings to install in wordpress environment.

    if (password === correctPassword) {
      onAuthenticated(true);
    } else {
      alert('Incorrect password!');
    }
  };

  return (
    <div className="access-wall d-flex justify-content-center align-items-center vh-100">
      <form onSubmit={handleSubmit} className="access-form text-center p-4 rounded shadow">
        <Logo
          alt="APIDNA Logo"
          className="navbar-logo mb-4"
          width={223}
          height={66}
        />
        <h2 className='mb-4'>Validator Tool</h2>
        <p className="mb-4 text-light">Enter Password to Access</p>
        <div className="form-group mb-3">
          <input
            type="password"
            className="form-control"
            style={{ width: "60%", margin: "auto"}}
            id="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-orange">Submit</button>
      </form>
    </div>
  );
};

export default AccessWall;
